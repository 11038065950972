<template>
    <div class="bank-account-content a-flex-rfsc">
        <div class="bank-info " v-if="agencyDetail&&agencyDetail.status==4">
            <div class="a-flex-rfsc ">
                <span class="a-c-normal pdr8 a-fs-14">甲方</span>
                <span class="a-c-master a-fs-14">{{ agencyDetail&&agencyDetail.parentCompanyName?agencyDetail.parentCompanyName:'' }}</span>
            </div>
            <div class="a-flex-rfsc a-mt-8">
                <span class="a-c-normal pdr8 a-fs-14">乙方</span>
                <span class="a-c-master a-fs-14">{{ agencyDetail&&agencyDetail.companyName?agencyDetail.companyName:'' }}</span>
            </div>
            <div class="a-flex-rfsc a-mt-8">
                <span class="a-c-normal pdr8 a-fs-14">乙方营收占比</span>
                <span class="a-c-master a-fs-14">{{ agencyDetail&&agencyDetail.rate?(agencyDetail.rate/100).toFixed(2):0 }}%</span>
            </div>
            <div class="a-flex-rfsb a-mt-8">
                <span class="a-c-normal pdr8 a-fs-14" style="white-space:nowrap;">代理合同</span>
                <span class="a-c-master a-fs-14">{{ agencyDetail&&agencyDetail.contractFile?agencyDetail.contractFile:'' }}</span>
                <div class="a-flex-rfsc font-point" @click="exportfile">
                    <span class="a-c-blue a-fs-14 a-ml-11" style="white-space:nowrap;">下载</span>
                    <img src="../../../assets/icon/downlaod-icon.png" style="width:14px;height:14px;margin-left:6px" alt="">
                </div>
            </div>
            <div class="tips" v-if="agencyDetail&&agencyDetail.agencyLevel">
                <span>{{ agencyDetail&&agencyDetail.agencyLevel?agencyDetail.agencyLevel:'-' }}级代理</span>
            </div>
        </div>
        <div class="a-flex-rcc a-flex-1" v-else>
            <el-empty description="您还没有签订加盟合同" :image-size="240" :image="require('../../../assets/images/noData.png')"></el-empty>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        data () {
            return {
                agencyDetail: '',// 代理合同
                fileUrl: '',//文件导出url
            }
        },
        mounted () {
            this.getAgencyDetail()
        },
        computed:{
            ...mapState({
                company: state => state.company.company
            })
        },
        methods: {
            getAgencyDetail () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getAgencyDetail,
                    method: "get",
                    params:{
                        agencyCompanyId: this.company.id
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.agencyDetail = res.result.data
                        this.fileUrl = res.result.excelUrl
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            },
            exportfile () {
                if(!this.fileUrl) {
                    this.$message.warning('暂无可下载文件')
                    return
                }
                this.$confirm('是否确认导出文件？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Utils.downloadUrlFile(this.fileUrl, '代理合同')
                }).catch(_=>{ })
            }
        }
    }
</script>

<style lang="scss" scoped>
.bank-account-content{
    background: #FFFFFF;
    border-radius: 3px;
    margin-top: 11px;
    padding: 24px
}
.bank-info{
    width: 522px;
    margin-bottom: 11px;
    background: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #EBF0F5;
    padding: 17px;
    position: relative;
}
.bank-info-head{
    background: #F2F8FF;
    border-radius: 3px 3px 0px 0px;
    padding: 9px 17px
}
.pdtb11{
    padding: 11px 0
}
.ffa900{
    color: #FFA900
}
.bank-btn1{
    width: 60px;
    height: 32px;
    padding: 4px 8px;
    background: #D9EBFF;
    border-radius: 16px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #007AFF;
}
.bank-btn2{
    width: 60px;
    height: 32px;
    padding: 4px 8px;
    background: #F5F7FA;
    border: 1px solid #EBF0F5;
    border-radius: 16px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #606366;
}
.add-bank-content{
    height: 158px;
    background: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #DADDE0;
    cursor: pointer;
    width: 522px;
    margin-bottom: 11px
}
.mgr11{
    margin-right: 11px
}
.pdr8{
    padding-right: 8px
}
.tips{
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 3px;
    border: 1px solid #FFA900;
    padding: 2px 7px;
    color: #FFA900;
    font-size: 8px
}
</style>