<template>
    <div class="trade-content">
        <div class="personal" v-if="memberType != 2">
            <div class="step-content">
                <el-steps :active="stepAcPerson">
                    <el-step>
                        <div slot="icon" class="trade-step-icon ">
                            <span class="trade-step-icon-num a-flex-rcc" :class="stepAcPerson>0?'step-color-ac':'step-color-unac'">1</span>
                            <span class="trade-step-icon-text">绑定手机号</span>
                        </div>
                    </el-step>
                    <el-step>
                        <div slot="icon" class="trade-step-icon ">
                            <span class="trade-step-icon-num a-flex-rcc" :class="stepAcPerson>0?'step-color-ac':'step-color-unac'">1</span>
                            <span class="trade-step-icon-text">实名认证</span>
                        </div>
                    </el-step>
                    <el-step>
                        <div slot="icon" class="trade-step-icon ">
                            <span class="trade-step-icon-num a-flex-rcc" :class="stepAcPerson>0?'step-color-ac':'step-color-unac'">1</span>
                            <span class="trade-step-icon-text">绑定银行卡</span>
                        </div>
                    </el-step>
                    <el-step>
                        <div slot="icon" class="trade-step-icon ">
                            <span class="trade-step-icon-num a-flex-rcc" :class="stepAcPerson>0?'step-color-ac':'step-color-unac'">1</span>
                            <span class="trade-step-icon-text">签署电子协议</span>
                        </div>
                    </el-step>
                </el-steps>
            </div>
            <div class="pb30">
                <span class="a-fs-16 a-c-master a-fw-700">绑定手机号</span>
                <div class="a-flex-rfsc">
                    <span class="a-fs-14 c7979 width40">手机号码</span>
                    <span class="a-fs-14 a-c-master a-ml-11 pdtb11">{{ tradeAuthInfo.tlAccount&&tradeAuthInfo.tlAccount.bindMobile?tradeAuthInfo.tlAccount.bindMobile:'-' }}</span>
                </div>
            </div>
            <div class="pb30">
                <span class="a-fs-16 a-c-master a-fw-700">实名认证</span>
                <div class="a-flex-rfsc">
                    <span class="a-fs-14 c7979 width40">姓名</span>
                    <span class="a-fs-14 a-c-master a-ml-11 pdtb11 ">{{ tradeAuthInfo.orcUserName?tradeAuthInfo.orcUserName:'-' }}</span>
                    <span class="a-fs-14 c7979" style="padding-left:251px">身份证号</span>
                    <span class="a-fs-14 a-c-master a-ml-11 pdtb11">{{ tradeAuthInfo.ocrIcCardNo?tradeAuthInfo.ocrIcCardNo:'-' }}</span>
                </div>
            </div>
            <!-- <div class="pb30">
                <span class="a-fs-16 a-c-master a-fw-700">绑定银行卡</span>
                <div class="a-flex-rfsc">
                    <span class="a-fs-14 c7979 width40">手机号码</span>
                    <span class="a-fs-14 a-c-master a-ml-11 pdtb11">18909897654</span>
                </div>
            </div>
            <div class="pb30">
                <span class="a-fs-16 a-c-master a-fw-700">电子协议</span>
                <div class="a-flex-rfsc">
                    <span class="a-fs-14 c7979 width40">手机号码</span>
                    <span class="a-fs-14 a-c-master a-ml-11 pdtb11">18909897654</span>
                </div>
            </div> -->
        </div>
        <div class="enterprise" v-if="memberType == 2">
            <div class="step-content">
                <el-steps :active="stepAcEnterprise">
                    <el-step>
                        <div slot="icon" class="trade-step-icon ">
                            <span class="trade-step-icon-num a-flex-rcc" :class="stepAcEnterprise>0?'step-color-ac':'step-color-unac'">1</span>
                            <span class="trade-step-icon-text">提交开户资料</span>
                        </div>
                    </el-step>
                    <el-step>
                        <div slot="icon" class="trade-step-icon ">
                            <span class="trade-step-icon-num a-flex-rcc" :class="stepAcEnterprise>1?'step-color-ac':'step-color-unac'">2</span>
                            <span class="trade-step-icon-text">绑定手机号</span>
                        </div>
                    </el-step>
                    <el-step>
                        <div slot="icon" class="trade-step-icon ">
                            <span class="trade-step-icon-num a-flex-rcc" :class="stepAcEnterprise>2?'step-color-ac':'step-color-unac'">3</span>
                            <span class="trade-step-icon-text">签署电子协议</span>
                        </div>
                    </el-step>
                </el-steps>
            </div>
            <div class="pb30">
                <span class="a-fs-16 a-c-master a-fw-700">开户资料</span>
                <div class="account-info">
                    <div class="a-fs-16 a-c-master a-fw-700 a-line-b-e0 pb11">基本信息</div>
                    <div class="a-flex-rfsc a-fs-14 a-flex-wrap" style="padding: 11px 0 30px">
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">企业简称</span>
                            <span class="a-c-master a-ml-11">{{ tradeAuthInfo.companyBrand }}</span>
                        </div>
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">企业全称</span>
                            <span class="a-c-master a-ml-11">{{ tradeAuthInfo.companyName }}</span>
                        </div>
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">所在地区</span>
                            <span class="a-c-master a-ml-11">{{ tradeAuthInfo.companyAddress }}</span>
                        </div>
                    </div>
                    <div class="a-fs-16 a-c-master a-fw-700 a-line-b-e0 pb11">法人信息</div>
                    <div class="a-flex-rfsc a-fs-14 a-flex-wrap" style="padding: 11px 0 30px">
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">法人姓名</span>
                            <span class="a-c-master a-ml-11">{{ tradeAuthInfo.legalName?tradeAuthInfo.legalName:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">证件类型</span>
                            <span class="a-c-master a-ml-11">{{ tradeAuthInfo.legalIdentityType | initLegalIdentityType }}</span>
                        </div>
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">证件号</span>
                            <span class="a-c-master a-ml-11">{{ tradeAuthInfo.legalIds?tradeAuthInfo.legalIds:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">手机号码</span>
                            <span class="a-c-master a-ml-11">{{ tradeAuthInfo.legalMobile?tradeAuthInfo.legalMobile:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">证件照片</span>
                            <LeBtnPreviewImg class="a-ml-11" :imgList="[tradeAuthInfo.legalPic]"></LeBtnPreviewImg>
                        </div>
                    </div>
                    <div class="a-fs-16 a-c-master a-fw-700 a-line-b-e0 pb11">营业执照</div>
                    <div class="a-flex-rfsc a-fs-14 a-flex-wrap" style="padding: 11px 0 30px">
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">统一社会信用代码</span>
                            <span class="a-c-master a-ml-11">{{ tradeAuthInfo.uniCredit?tradeAuthInfo.uniCredit:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">营业执照</span>
                            <LeBtnPreviewImg class="a-ml-11" :imgList="[tradeAuthInfo.uniPic]"></LeBtnPreviewImg>
                        </div>
                    </div>
                    <div class="a-fs-16 a-c-master a-fw-700 a-line-b-e0 pb11">银行账户</div>
                    <div class="a-flex-rfsc a-fs-14 a-flex-wrap" style="padding: 11px 0 0px">
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">企业对公账户</span>
                            <span class="a-c-master a-ml-11">{{ tradeAuthInfo.publicBank&&tradeAuthInfo.publicBank.accountNo?tradeAuthInfo.publicBank.accountNo:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">银行账户名</span>
                            <span class="a-c-master a-ml-11">{{ tradeAuthInfo.publicBank&&tradeAuthInfo.publicBank.accountName?tradeAuthInfo.publicBank.accountName:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">开户银行名称</span>
                            <span class="a-c-master a-ml-11">{{ tradeAuthInfo.publicBank&&tradeAuthInfo.publicBank.bankName?tradeAuthInfo.publicBank.bankName:'-' }}</span>
                        </div>
                        <div class="a-flex-rfsc pb11 width500">
                            <span class="a-c-normal">开户行支行名称</span>
                            <span class="a-c-master a-ml-11">{{ tradeAuthInfo.publicBank&&tradeAuthInfo.publicBank.branchName?tradeAuthInfo.publicBank.branchName:'-' }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pb30">
                <span class="a-fs-16 a-c-master a-fw-700">绑定手机号</span>
                <div class="a-flex-rfsc">
                    <span class="a-fs-14 c7979 width40">手机号码</span>
                    <span class="a-fs-14 a-c-master a-ml-11 pdtb11">{{ tradeAuthInfo.tlAccount&&tradeAuthInfo.tlAccount.bindMobile?tradeAuthInfo.tlAccount.bindMobile:'-' }}</span>
                </div>
            </div>
            <div class="pb30">
                <span class="a-fs-16 a-c-master a-fw-700">电子协议</span>
                <div class="a-flex-rfsc">
                    <span class="a-fs-14 c7979">{{ tradeAuthInfo.agreementName?tradeAuthInfo.agreementName:'-' }}</span>
                    <span class="a-fs-14 a-c-master a-ml-11 pdtb11">{{ tradeAuthInfo.agreementSignStatus==1?'已签署':'未签署' }}</span>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import LeBtnPreviewImg from '../../components/poppup/le-btn-preview-img.vue'
    import { mapState } from 'vuex';
    export default {
        components: {
            LeBtnPreviewImg
        },
        data () {
            return {
                memberType: '',
                stepAcPerson: 0,
                stepAcEnterprise: 0,
                tradeAuthInfo: '',
            }
        },
        mounted () {
            this.getCompanyAccount()
            this.getUserAccountStatus()
        },
        computed: {
            ...mapState({
                company: state => state.company.company
            })
        },
        filters: {
            initLegalIdentityType (val) {
                switch (val) {
                    case 1:
                        return '身份证'
                        break;
                    case 2:
                        return '护照'
                        break;
                    case 3:
                        return '军官证'
                        break;
                    case 4:
                        return '回乡证'
                        break;
                    case 5:
                        return '台胞证'
                        break;
                    case 6:
                        return '警官证'
                        break;
                    case 7:
                        return '士兵证'
                        break;
                    case 99:
                        return '其他'
                        break;
                    default:
                        return '-'
                        break;
                }
            }
        },
        methods: {
            getUserAccountStatus () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getUserAccountStatus,
                    method: "get",
                }).then(res => {
                    if(res.result.code == 0){
                        let datas = res.result.data
                        // 个人
                        if(datas.bindPhone){
                            this.stepAcPerson = 1
                        }
                        if(datas.ocr){
                            this.stepAcPerson = 2
                        }
                        if(datas.bindBankAccount){
                            this.stepAcPerson = 3
                        }
                        if(datas.signDocument){
                            this.stepAcPerson = 4
                        }
                        // 企业
                        if(datas.setCompanyInfo){
                            this.stepAcEnterprise = 1
                        }
                        if(datas.bindPhone){
                            this.stepAcEnterprise = 2
                        }
                        if(datas.signDocument){
                            this.stepAcEnterprise = 3
                        }
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            },
            // 获取通联交易权限信息
            getCompanyAccount () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getCompanyAccount,
                    method: "get",
                    params: {
                        currentCompanyId: this.company.id
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.tradeAuthInfo = res.result.data
                        this.memberType = this.tradeAuthInfo.tlAccount.memberType
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.trade-content{
    background: #FFFFFF;
    border-radius: 3px;
    margin-top: 11px;
    padding: 24px
}
.step-content{
    padding: 11px 30px;
    margin-bottom: 29px;
}
/deep/ .trade-step-icon{
    padding: 0 6px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    .trade-step-icon-num{
        width: 20px;
        height: 20px;
        color: #FFFFFF;
        font-size: 10px;
        border-radius: 50%;
        margin-right: 6px
    }
    .step-color-ac{
        background: #007AFF;
    }
    .step-color-unac{
        background: #ADB0B3;
    }
    .trade-step-icon-text{
        font-size: 14px;
    }
}
/deep/ .el-step__icon.is-text{
    width: auto;
    border: none
}
.account-info{
    background: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #EBF0F5;
    padding: 11px 17px;
    margin-top: 11px
}
.c7979{
    color: #797979
}
.pdtb11{
    padding: 11px 0
}
.pb11{
    padding-bottom: 11px
}
.pb30{
    padding-bottom: 30px
}
.width40{
    width: 60px
}
.width500{
    width: 500px
}
</style>