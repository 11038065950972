<template>
    <div>
        <div class="content1 a-flex-rsbfs">
            <div class="a-flex-cfsfs">
                <span class="a-c-master a-fs-14 a-fw-700 a-pb-15">我的余额（元）</span>
                <div class="a-flex-rfsb a-pb-6">
                    <span class="a-c-blue a-fs-14 a-fw-700">￥</span>
                    <span class="a-c-blue a-fs-17 a-fw-700">{{ util.numFormat((businessInfo.balance || 0) + (businessInfo.serviceProfitBalance || 0)) }}</span>
                </div>
                <div class="a-flex-rfsc" @click="toBillsList">
                    <span class="txt1">还有￥{{ util.numFormat(unConfirmAmount) }}未入账，去查看</span>
                    <img src="../../../assets/icon/right-icon.png" style="width:12px;height:12px;margin-left:3px" alt="">
                </div>
            </div>
            <div class="a-flex-cfsfs" v-if="businessInfo.balance && businessInfo.serviceProfitBalance">
                <span class="a-c-master a-fs-14 a-fw-700 a-pb-15">玖电钱包余额（元）</span>
                <div class="a-flex-rfsb a-pb-6">
                    <span class="a-c-blue a-fs-14 a-fw-700">￥</span>
                    <span class="a-c-blue a-fs-17 a-fw-700">{{ util.numFormat(businessInfo.balance || 0) }}</span>
                </div>
            </div>
            <div class="a-flex-cfsfs" v-if="businessInfo.balance && businessInfo.serviceProfitBalance">
                <span class="a-c-master a-fs-14 a-fw-700 a-pb-15">独立钱包余额（元）</span>
                <div class="a-flex-rfsb a-pb-6">
                    <span class="a-c-blue a-fs-14 a-fw-700">￥</span>
                    <span class="a-c-blue a-fs-17 a-fw-700">{{ util.numFormat(businessInfo.serviceProfitBalance || 0) }}</span>
                </div>
            </div>
            <div class="a-flex-rsbc">
                <el-button type="primary" class="s-btn-add" @click="toWithdrawal">提现</el-button>
            </div>
        </div>
        <div class="content2">
            <div class="datepick" v-show="tabAcIndex=='srmx'">
                <le-date-range  label="" :minDate.sync="startDate" :maxDate.sync="endDate" />
            </div>
            <div class="">
                <el-tabs v-model="tabAcIndex" @tab-click="handleClick">
                    <el-tab-pane label="收入明细" name="srmx">
                        <le-pagview @setData="setTableData1" :pageParam="pageParam1">
                            <el-table 
                                :data="tableData1" 
                                :highlight-current-row="true" 
                                style="width: 100%">
                                <el-table-column prop="createTimeText" label="收入时间" min-width="200"></el-table-column>
                                <el-table-column prop="typeText" label="收入类型" min-width="240"></el-table-column>
                                <el-table-column prop="companyName" label="收入金额（元）" min-width="200">
                                    <template slot-scope="{ row }">
                                        <span>￥{{ util.numFormat(row.changeAmount) }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </le-pagview>
                    </el-tab-pane>
                    <el-tab-pane label="提现成功明细" name="txcg">
                        <le-pagview @setData="setTableData2" :pageParam="pageParam2">
                            <el-table 
                                :data="tableData2" 
                                :highlight-current-row="true" 
                                style="width: 100%">
                                <el-table-column prop="payoutTimeText" label="提现成功时间" min-width="140"></el-table-column>
                                <el-table-column prop="stationName" label="提现金额（元）" min-width="140">
                                    <template slot-scope="{ row }">
                                        <span>￥{{ util.numFormat(row.amount) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="companyName" label="手续费（元）" min-width="120">
                                    <template slot-scope="{ row }">
                                        <span>￥{{ util.numFormat(row.totalFee) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="payoutMethodText" label="提现渠道" min-width="140"></el-table-column>
                                <el-table-column prop="createTimeText" label="申请时间" min-width="140"></el-table-column>
                                <el-table-column prop="applyUserName" label="申请人" min-width="140">
                                    <template slot-scope="{ row }">
                                        <span>{{ row.applyUserName?row.applyUserName:'-' }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="stateText" label="状态信息" min-width="100"></el-table-column>
                            </el-table>
                        </le-pagview>
                    </el-tab-pane>
                    <el-tab-pane label="提现申请记录" name="sqtx">
                        <le-pagview @setData="setTableData3" :pageParam="pageParam3">
                            <el-table 
                                :data="tableData3" 
                                :highlight-current-row="true" 
                                style="width: 100%">
                                <el-table-column prop="stationName" label="提现金额（元）" min-width="140">
                                    <template slot-scope="{ row }">
                                        <span>￥{{ util.numFormat(row.amount) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="companyName" label="手续费（元）" min-width="120">
                                    <template slot-scope="{ row }">
                                        <span>￥{{ util.numFormat(row.totalFee) }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="payoutMethodText" label="提现渠道" min-width="140"></el-table-column>
                                <el-table-column prop="createTimeText" label="申请时间" min-width="140"></el-table-column>
                                <el-table-column prop="applyUserName" label="申请人" min-width="140">
                                    <template slot-scope="{ row }">
                                        <span>{{ row.applyUserName?row.applyUserName:'-' }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="stateText" label="状态信息" min-width="100"></el-table-column>
                                <el-table-column prop="name" label="操作" min-width="75" fixed="right">
                                    <template slot-scope="scope">
                                        <el-tooltip class="item a-flex-rcc" effect="dark" content="详情" placement="top">
                                            <img src="../../../assets/icon/option-detail.png" class="a-wh-16" @click="toWithdrawalDetails(scope.row)" />
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </le-pagview>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    import { mapState } from 'vuex';
    export default {
        data () {
            return {
                util: util,
                tabAcIndex: 'srmx',
                unConfirmAmount: '',//未入账金额
                startDate: '',
                endDate: '',
                tableData1: [],
                pageParam1: {
                    url: this.$Config.apiUrl.getBalanceChangeList,
                    method: "post",
                    params: {
                        startTime: "",
                        endTime: "",
                        searchType: 0,//启用状态
                    },
                    freshCtrl: 1,
                },
                tableData2: [],
                pageParam2: {
                    url: this.$Config.apiUrl.withdrawSuccessList,
                    method: "post",
                    params: {},
                    freshCtrl: 1,
                },
                tableData3: [],
                pageParam3: {
                    url: this.$Config.apiUrl.getWithdrawList,
                    method: "post",
                    params: {},
                    freshCtrl: 1,
                }
            }
        },
        props:['balance','businessInfo'],
        mounted () {
            this.getUnConfirmAmount()
        },
        watch:{
            startDate (newval, oldval) {
                this.pageParam1.params.startTime = newval
            },
            endDate (newval, oldval) {
                this.pageParam1.params.endTime = newval
            },
            searchTime (newval, oldval) {
                if(this.tabAcIndex == 'srmx') {
                    this.pageParam1.freshCtrl++
                }else if(this.tabAcIndex == 'txcg'){
                    this.pageParam2.freshCtrl++
                }else if(this.tabAcIndex == 'sqtx'){
                    this.pageParam3.freshCtrl++
                }
            }
        },
        computed:{
            searchTime () {
                return this.startDate + this.endDate
            },
            ...mapState({
                company: state => state.company.company
            })
        },
        methods:{
            // 获取未入账金额
            getUnConfirmAmount () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.getUnConfirmAmount,
                    method: "get",
                    params: {
                        companyId: this.company.id
                    }
                }).then(res => {
                    if(res.result.code == 0){
                        this.unConfirmAmount = res.result.data
                    }else{
                        this.$message.error(res.result.message)
                    }
                })
            },
            setTableData1 (datas) {
                this.tableData1 = datas
            },
            setTableData2 (datas) {
                this.tableData2 = datas
            },
            setTableData3 (datas) {
                this.tableData3 = datas
            },
            handleClick (tab, event) {
                // console.log(tab, event)
                if(this.tabAcIndex == 'srmx') {
                    this.pageParam1.freshCtrl++
                }else if(this.tabAcIndex == 'txcg'){
                    this.pageParam2.freshCtrl++
                }else if(this.tabAcIndex == 'sqtx'){
                    this.pageParam3.freshCtrl++
                }
            },
            toWithdrawal () {
                this.$router.push({
                    path:'/businessCenter/withdrawal',
                    query:{
                        userBalance: (this.businessInfo.balance || 0) + (this.businessInfo.serviceProfitBalance || 0)
                    }
                })
            },
            // 查看未入账账单
            toBillsList () {
                this.$router.push({
                    path:'/revenueBills/revenueBills-list',
                    query:{
                        billsStatus: 0, // 账单状态为 未入账
                    }
                })
            },
            toWithdrawalDetails (datas) {
                this.$router.push({
                    path: '/withdrawal/withdrawal-detail',
                    query:{
                        code: datas.id
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.content1{
    background: #FFFFFF;
    border-radius: 3px;
    margin: 11px 0;
    padding: 24px;
}
.content2{
    background: #FFFFFF;
    border-radius: 3px;
    margin: 11px 0;
    padding: 24px;
    position: relative;
    /deep/ .el-tabs__item {
        // color: #007aff;
        font-size: 14px;
    }

    /deep/ .el-tabs__item.is-active {
        color: #007AFF;
        font-weight: bold;
    }

    /deep/ .el-tabs__nav-wrap::after {
        background: none !important;
    }

    /deep/ .el-tabs__header {
        // padding-left: 17px;
        // padding-top: 20px;
        // margin-bottom: 2px;
        // border-radius: 3px;
        // background: #FFFFFF
        padding: 0
    }
}
.a-fs-17{
    font-size: 18px
}
.txt1{
    font-size: 14px;
    color: #606366;
    cursor: pointer;
}
.txt1:hover{
    text-decoration:underline;
}
.datepick{
    position: absolute;
    top: 12px;
    right: 24px;
    z-index: 7;
}
</style>